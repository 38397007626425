import React, { useEffect } from "react";
import styles from "./AddonProduct.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import { useSelector } from "react-redux";
import { round } from "lodash";
import Loader from "../../CustomerForm/FKLoader/Loader";

function AddonProduct({ productId, extraProduct }) {
  const product = useSelector((state) =>
    state.productState.products.find((element) => element.id === productId)
  );
  const productHubIdsRabatt = [718, 754];
  const productHubIdsFordelspoeng = [734];
  useEffect(() => {
    if (!product) {
      productActionDispatchers.getProductInfo(productId, extraProduct?.campaigns[0]?.productHubId);
    }
  }, []);

  return (
    <div className={`${IsFKTheme && styles.containerFK}`}>
      <div className={styles.boxTitle}> Tilleggsprodukt</div>
      {!product || product?.isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <div className={styles.addonName}>{product?.productHubInfo?.name}</div>
          <div
            className={styles.summary}
            dangerouslySetInnerHTML={{ __html: product?.textGroups[2] }}
          />
          {extraProduct?.campaignName && (
            <div className={styles.productName}>{extraProduct?.campaigns[0]?.campaignName}</div>
          )}
          {extraProduct?.campaigns[0]?.campaignName && product && (
            <div
              className={styles.productInfo}
              dangerouslySetInnerHTML={{ __html: product.campaignInfo }}
            />
          )}
          {productHubIdsRabatt.includes(extraProduct?.productHubId) && (
            <div>{`Du får ${extraProduct.priceElements[0]?.price} kr rabatt på første strømregning`}</div>
          )}
          {productHubIdsFordelspoeng.includes(extraProduct?.productHubId) && (
            <div>{`Du får ${extraProduct?.priceElements[0]?.price} fordelspoeng på ditt kundeforhold`}</div>
          )}
          {extraProduct?.priceElements[0]?.priceElementDefinitionId === 418 && (
            <div className={styles.summary}>
              {`Avtalen har ${extraProduct.minimumTermDays === null ? 6 : round(extraProduct.minimumTermDays / 30)} mnd bindingstid og et bruddgebyr på ${extraProduct.priceElements[0].price}kr`}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AddonProduct;
