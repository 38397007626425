import React, { useEffect, useState } from "react";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import styles from "./BedriftConfirmOrder.module.scss";
import Icon from "../../Icon/Icon";
import { bedriftSummarySelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import { connect } from "react-redux";
import BedriftProductInfoDialog from "./BedriftProductInfoDialog";
import moment from "moment/moment";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import Loader from "../../CustomerForm/FKLoader/FKLoader";
import style from "../Terms/TermLinks.module.scss";
import config from "../../../config";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const BedriftConfirmOrder = ({ onConfirm, onBack, form, deliveryPoints, isloading }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deliveryPointToBeAccepted, setDeliveryPointsToBeAccepted] = useState();
  const [bedriftAccept, setBedriftAccept] = useState(false);
  const baseUrl =
    config.env === "prod"
      ? "https://avtale.fjordkraft.no/api/term/"
      : "https://termsandconditions-fjordkraftservices-test.azurewebsites.net/api/term/";
  const link = baseUrl + form.producthubInfo.guid;

  const handleConfirm = () => {
    saleActionDispatchers.submitBedriftFormAction(form, deliveryPointToBeAccepted);
  };

  function getInvoiceMethod() {
    switch (form.chosenDistributionMethod) {
      case 3:
        return "Papir: " + form.address + ", " + form.zipCode;
      case 4:
        return "EHF: " + form.ehfOrgNumber;
      case 1:
        return "E-post: " + form.invoiceEmail;
      default:
        return "Ikke satt";
    }
  }

  useEffect(() => {
    setDeliveryPointsToBeAccepted(deliveryPoints.filter((item) => item.selected === true));
  }, []);

  return (
    <>
      <BedriftProductInfoDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        form={form}
      />
      <FormPageContainer>
        {isloading ? (
          <Loader
            text={"Sender inn bestilling"}
            className={styles.loader}
            style={{ marginTop: "200px" }}
          />
        ) : (
          <div className={styles.content}>
            <h1 className={"fallIn-"}>Oppsummering</h1>
            <div className={"slide"}>
              <div className={`whiteBox ${styles.box}`}>
                <div className={styles.item}>
                  <div className={`${styles.grayText}`}>Du har valgt</div>
                  <h4 className={styles.productName} onClick={() => setDialogOpen(true)}>
                    {form.avtaleName}
                    <Icon className={styles.changeProductIcon} icon={"info"} />
                  </h4>
                </div>

                <div className={styles.item}>
                  <div>{form.companyName}</div>
                  <div>
                    {form.firstName} {form.lastName}
                  </div>
                  <div className={styles.grayText}>Tlf: {form.phone}</div>
                  <div className={styles.grayText}>E-post: {form.email}</div>
                </div>

                {deliveryPoints
                  .filter((d) => d.selected)
                  .map((d) => (
                    <div className={styles.item}>
                      <div>{d.address}</div>
                      <div>{d.zipCode}</div>
                      <div>{d.city}</div>
                      <div className={styles.grayText}>
                        Målernr:{" "}
                        {d.isSkipped
                          ? "Ikke valgt"
                          : d.elhubData?.meterIdentification ?? d.meterIdentification}
                      </div>
                      <div className={styles.grayText}>
                        Målepunkt ID: {d.elhubData?.meteringPointIdentification}
                      </div>
                      <hr></hr>
                    </div>
                  ))}

                <div className={styles.item}>
                  <div>Oppstartsdato</div>
                  <div className={styles.grayText}>
                    {moment(deliveryPoints.filter((d) => d.selected)[0].moveInDate)
                      .locale("nb")
                      .format("DD. MMMM YYYY") ?? "Ikke valgt"}
                  </div>
                </div>

                <div className={styles.item}>
                  <div>Faktura sendes til</div>
                  <div className={styles.grayText}>{getInvoiceMethod()}</div>
                </div>
              </div>
              <div className={styles.checkBoxContainer}>
                <button
                  className={styles.checkBoxButton}
                  onClick={() => setBedriftAccept(!bedriftAccept)}
                >
                  {bedriftAccept ? (
                    <CheckBox className={styles.checkBoxIcon} />
                  ) : (
                    <CheckBoxOutlineBlank className={styles.checkBoxIcon} />
                  )}
                </button>
                <div className={style.checkBoxTextTerm}>
                  Jeg aksepterer{" "}
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    vilkårene
                  </a>{" "}
                  for avtalen for strøm.
                </div>
              </div>
              <button
                className={"btnPrimary " + styles.btn}
                disabled={!bedriftAccept}
                onClick={handleConfirm}
              >
                Bekreft bestilling
              </button>
            </div>
          </div>
        )}
      </FormPageContainer>
    </>
  );
};

export default connect(bedriftSummarySelector)(BedriftConfirmOrder);
