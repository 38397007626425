import React from "react";
import styles from "./CheckBox.module.scss";
import Icon from "../Icon/Icon";
import { IsFKTheme } from "../../Utils/Themes";

function CheckBox({ header, header2, content, checked, onClick, className }) {
  return (
    <button
      className={`${checked && `${styles.boxChecked} ${IsFKTheme && styles.boxCheckedFK}`} ${styles.box} ${IsFKTheme && styles.boxFK} ${className}`}
      onClick={onClick}
    >
      <div
        className={
          checked
            ? styles.headerContainer + " " + styles.headerContainerChecked
            : styles.headerContainer
        }
      >
        <div
          className={
            checked
              ? styles.iconContainer + " " + styles.iconContainerChecked
              : styles.iconContainer
          }
        >
          <Icon icon={"check"} className={styles.icon} />
        </div>
        <div className={styles.header}>{header}</div>
        <div className={styles.header2}>{header2}</div>
      </div>
      <div className={checked ? styles.content + " " + styles.contentChecked : styles.content}>
        {content}
      </div>
    </button>
  );
}

export default CheckBox;
