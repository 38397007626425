import React from "react";
import styles from "./SubscriptionConsent.module.scss";
import TilbudTK from "../../../assets/Images/TK/TilbudTK.png";
import SubscriptionFK from "../../../assets/Images/FK/SubscriptionConsentFramed.svg";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { IsFKTheme } from "../../../Utils/Themes";
import { partnerBonusesLink, privacyLink, privacyLinkMobile } from "../../../Utils/ExternalLinks";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { formSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import { connect } from "react-redux";
import MobileMascotBlush from "../../../assets/Images/FK/Mobil/MobileMascotBlush.svg";
import WhiteButtonList from "../../ButtonList/WhiteButtonList";
import { pages } from "../../../Utils/CustomerFormHelper";
import store from "../../../ReduxModules/Store/Store";

function SubscriptionConsent({ onBack, onConfirm, onSubmit, hideFooter, onSkip, isMobile }) {
  const shouldGoToTobbPage = () => {
    const state = store.getState().saleState.form.producthubInfo;
    if (state.tags !== null && state.tags?.includes("TOBB")) {
      pageStepActionDispatchers.addStep(pages.subscription, pages.tobbId);
      return true;
    }
    return false;
  };

  const handleSubmit = (subscriptionConsent) => {
    if (onSubmit) {
      onSubmit(subscriptionConsent);
    } else {
      //Todo, fixe seinere : >
      saleActionDispatchers.updateFormAction({ subscriptionConsent });
      if (onConfirm) {
        onConfirm();
      } else {
        shouldGoToTobbPage(); //Todo, fixe seinere : >
        pageStepActionDispatchers.goToNextStep(window.location.pathname);
      }
    }
  };
  return (
    <FormPageContainer onBack={onBack} hideFooter={hideFooter}>
      <h1 className={"fallIn-"}>
        {isMobile ? "Vil du ha gode tilbud og rabatter?" : "Få mer for pengene"}
      </h1>
      {isMobile ? (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={MobileMascotBlush}
            alt={"mobileMascot"}
          />
        </div>
      ) : (
        <div className={styles.imageContainer}>
          <div className={"frameImageContainer"}>
            <img
              className={"frameImage formPage-background fade-"}
              src={IsFKTheme ? SubscriptionFK : TilbudTK}
              alt={"Tilbud"}
            />
          </div>
        </div>
      )}
      <div className={"slide"}>
        <div className={styles.text}>
          Ønsker du nyheter og gode tilbud på varer og tjenester som du kan benytte hos oss eller
          våre{" "}
          <a href={partnerBonusesLink} target="_blank" rel="noopener noreferrer">
            samarbeidspartnere
          </a>
          ?
          <br />
          <br />
          Hvis du takker ja vil vi sende deg oppdateringer på e-post og SMS – det skjer stort sett
          to ganger i måneden. Du kan når som helst trekke tilbake samtykket på Min side. Du kan
          lese mer om hvordan vi behandler personopplysninger i vår{" "}
          <a
            href={isMobile ? privacyLinkMobile : privacyLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            personvernerklæring
          </a>
          .
        </div>
        <WhiteButtonList
          buttons={[
            {
              content: <div className={"title"}>Ja, jeg samtykker!</div>,
              onClick: () => handleSubmit(true)
            },
            {
              content: <div className={"title"}>Nei, takk</div>,
              onClick: () => handleSubmit(false)
            }
          ]}
        />
        {onSkip && (
          <button className={`btnUnderline ${styles.center}`} onClick={onSkip}>
            Spør meg senere
          </button>
        )}
      </div>
    </FormPageContainer>
  );
}

export default connect(formSelector)(SubscriptionConsent);
