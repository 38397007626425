import "bootstrap/dist/css/bootstrap.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import { Provider } from "react-redux";
import store from "./ReduxModules/Store/Store";
import { AuthProvider } from "react-oidc-context";
import * as Oidc from "oidc-client";
import { getQueryStrings } from "./Utils/UrlParser";
import saleActionDispatchers from "./ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { IsFKTheme } from "./Utils/Themes";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { customerServiceLink } from "./Utils/ExternalLinks";

const rootElement = document.getElementById("root");
const client = IsFKTheme ? "salesmodule.minSide.web" : "salesmodule.minSide.web.TK"; //Om det skal være TK eller FK klient.
const tobb = window.location.pathname.includes("tobb");
const redirectUri = tobb ? window.location.origin + "/tobb" : window.location.origin;

const oidcConfig = {
  authority: "https://auth.fjordkraft.no",
  client_id: client,
  response_type: "code",
  grant_type: "authorization_code",
  automatic_silent_renew: true,
  scope: `profile authapi transfer sales customer_ids offline_access apim openid myaccount_content`,
  redirect_uri: redirectUri,
  post_logout_redirect_uri: window.location.origin,
  //post_logout_redirect_uri: window.location.origin,
  acr_values: "context:customer context",
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: (user) => {
    const queryStrings = getQueryStrings(window.location.search);
    const state = JSON.parse(window.sessionStorage.getItem("state"));
    const nonce = window.sessionStorage.getItem("nonce");
    const stateKey = window.sessionStorage.getItem("stateKey");
    if (decodeURI(queryStrings.nonce) !== nonce && decodeURI(queryStrings.state) !== stateKey) {
      //TODO: Handle error
    }
    window.history.replaceState({}, window.document.title, window.location.pathname);
    if (state) saleActionDispatchers.setStateAction(state);
  }
};

export const ErrorFallback = ({ error }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column"
      }}
    >
      <h1>Noe gikk galt!</h1>
      <h5>Prøv igjen eller kontakt kundeservice...</h5>
      <div>
        Kundeservice:{" "}
        <a href={customerServiceLink} target="_blank" rel="noopener noreferrer">
          {customerServiceLink}
        </a>
      </div>
      <p>{error.message}</p>
    </div>
  );
};

export const logError2 = (error, info) => {
  const { componentStack } = info;

  if (window.newrelic) {
    window.newrelic.noticeError(error, {
      componentStack: componentStack,
      errorMessage: error.message,
      orangePage: "portableAgreementText"
    });
  }
};

export const logError = (error, info) => {
  const { componentStack } = info;

  if (window.newrelic) {
    window.newrelic.noticeError(error, {
      componentStack: componentStack,
      errorMessage: error.message
    });
  }
};

const root = createRoot(rootElement);
root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  </ErrorBoundary>
);

unregister();
/*registerServiceWorker({
    onSuccess: () => console.log('Register Success!'),
    onUpdate: reg => saleActionDispatchers.reloadAppAndUpdateCacheAction(reg),
});*/
