import { IsFKTheme } from "./Themes";

export const mainPageLink = IsFKTheme
  ? "https://www.fjordkraft.no"
  : "https://www.trondelagkraft.no";
export const customerServiceLink = IsFKTheme
  ? "https://www.fjordkraft.no/strom/kundeservice/"
  : "https://www.trondelagkraft.no/strom/kundeservice/";
export const powerDealsLink = IsFKTheme
  ? "https://www.fjordkraft.no/strom/stromavtale/"
  : "https://www.trondelagkraft.no/strom/stromavtaler/";
export const powerLink = IsFKTheme
  ? "https://www.fjordkraft.no/strom/"
  : "https://www.trondelagkraft.no/strom/";
export const mobileProductLink = IsFKTheme
  ? "https://www.fjordkraftmobil.no/?utm_source=trumf&utm_medium=mobilkampanje"
  : "https://www.trondelagkraft.no/mobilabonnement/";
export const partnerBonusesLink = IsFKTheme
  ? "https://www.fjordkraft.no/fordel"
  : "https://www.trondelagkraft.no/fordel";
export const privacyLink = IsFKTheme
  ? "https://www.fjordkraft.no/personvern"
  : "https://www.trondelagkraft.no/personvern";
export const privacyLinkMobile = IsFKTheme
  ? "https://www.fjordkraft.no/mobilabonnement/personvern"
  : "https://www.fjordkraft.no/mobilabonnement/personvern";
export const mobileRightOfWithdrawalLink =
  "https://www.fjordkraftmobil.no/globalassets/dokumenter/angrerett-mobil-fjordkraft.pdf?_ga=2.213696019.669330263.1653895635-355870562.1632385252";
export const powerRightOfWithdrawalLink = IsFKTheme
  ? "https://www.fjordkraft.no/globalassets/fjordkraft-medier/dokumenter/Angrerett.pdf"
  : "https://www.trondelagkraft.no/globalassets/trondelagkraft-medier/dokumenter/angrerett.pdf";
export const powerFormOnRightOfWithdrawalLink = IsFKTheme
  ? "https://www.fjordkraft.no/globalassets/fjordkraft-medier/dokumenter/angrerett-strom-s2-fjordkraft.pdf"
  : "https://www.trondelagkraft.no/globalassets/trondelagkraft-medier/dokumenter/angrerettskjema-strom-s2-trondelagkraft.pdf";
export const pulseRightOfWithdrawalLink =
  "https://www.fjordkraft.no/globalassets/fjordkraft-medier/dokumenter/Angrerett.pdf";
export const powerSmartTermsLink =
  "https://avtale.fjordkraft.no/api/term/b252627b-a9b2-4a10-b4ba-c90685c9a3f4";
