import { baseApiClient } from "./axios";

export const setAuthHeader = (token) => {
  baseApiClient.interceptors.request.use(
    function (config) {
      config.headers.Authorization = token ? "Bearer " + token : "";
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
};
