import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import { IsFKTheme } from "../../../Utils/Themes";
import styles from "./AddonProduct.module.scss";
import Loader from "../../CustomerForm/FKLoader/Loader";
import { getExtraProductPriceInfoBedrift } from "../../../Utils/AxiosFunctions";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { InfoOutlined } from "@mui/icons-material";

export function AddonProductBedrift({ productId, extraProduct }) {
  const product = useSelector((state) =>
    state.productState.products.find((element) => element.id === productId)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [priceInfo, setPriceInfo] = useState(null);
  const [productInfoOpen, setProductInfoOpen] = useState(false);

  useEffect(() => {
    if (!product) {
      productActionDispatchers.getProductInfo(productId, extraProduct?.campaigns[0]?.productHubId);
    }
    if (!priceInfo) {
      setIsLoading(true);
      getExtraProductPriceInfoBedrift(extraProduct.id).then((res) => {
        setPriceInfo(res);
        setIsLoading(false);
      });
    }
  }, []);

  function handleProductInfoClose() {
    setProductInfoOpen(false);
  }

  return (
    <div className={`${IsFKTheme && styles.containerFK}`}>
      <div className={styles.boxTitle}> Tilleggsprodukt</div>
      {!product || product?.isLoading || isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <div>
            <a className={styles.bedriftAddonName} onClick={() => setProductInfoOpen(true)}>
              {product?.productHubInfo?.name} <InfoOutlined fontSize={"small"} />
            </a>
          </div>
          {priceInfo && (
            <>
              <span className={styles.grayText}>Prisinfo</span>
              <div
                className={styles.bedriftAddon}
                dangerouslySetInnerHTML={{ __html: priceInfo }}
              />
            </>
          )}
        </>
      )}

      <Dialog open={productInfoOpen} onClose={handleProductInfoClose}>
        <DialogTitle>{product && product.productHubInfo?.name}</DialogTitle>
        <DialogContent dividers>
          <div dangerouslySetInnerHTML={{ __html: product?.productInfo }} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" color="success" onClick={handleProductInfoClose}>
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
