import { formAndUserSelector } from "../../ReduxModules/Selectors/SaleSelectors";
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import { Box } from "@mui/material";
import styles from "./TobbStyle.scss";
import saleActionDispatchers from "../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import SimpleInput from "../SimpleInput/SimpleInput";
import { pageStepAnimationDuration } from "../../Utils/CustomerFormHelper";
import CircularProgress from "@mui/material/CircularProgress";
import tobbActionDispatchers from "../../ReduxModules/Actions/TobbActions/TobbActionDispatcher";
import { tobbSelector } from "../../ReduxModules/Selectors/TobbSelector";
import { hasAuthParams, useAuth } from "react-oidc-context";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import customerActionDispatchers from "../../ReduxModules/Actions/CustomerActions/CustomerActionDispatchers";
import Loader from "../CustomerForm/FKLoader/Loader";
import store from "../../ReduxModules/Store/Store";
import { IsFKTheme } from "../../Utils/Themes";
import { setAuthHeader } from "../../Utils/axiosHeaders";
import config from "../../config";

function Tobb({}) {
  const [tobbId, setTobbId] = useState("");
  const auth = useAuth();
  const dispatch = useDispatch();
  const [isAxiosCalled, setIsAxiosCalled] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(true);
  let form = useSelector((state) => state.saleState.form);
  const saveInfo = () => {
    setAuthHeader(auth.user.access_token);
    let tlf = null;
    let customerId = null;
    let impersonator = false;
    if (auth.user && auth.user.profile.impersonating) {
      impersonator = true;
      customerId = auth.user.profile.impersonating;
    } else {
      tlf = auth.user.profile.phone_number;
      customerId = auth.user.profile.customerId;
    }
    AxiosFunction.getExistingCustomerSSNAndEmail(tlf, customerId, config.theme + "AS")
      .then((info) => {
        const customerInfo = {
          firstName:
            auth.user !== null && !impersonator
              ? auth.user.profile["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"]
              : "",
          lastName:
            auth.user !== null && !impersonator
              ? auth.user.profile["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"]
              : "",
          email: auth.user !== null && !impersonator ? auth.user.profile.email : "",
          phone:
            auth.user !== null && !impersonator
              ? auth.user.profile.phone_number[0] === "+"
                ? auth.user.profile.phone_number.slice(3)
                : auth.user.profile.phone_number
              : "",
          customerId: auth.user !== null ? customerId : "",
          ssn: info.toString()
        };
        const personNumber = info.toString();
        saleActionDispatchers.updateFormAction({ personNumber });
        dispatch({
          type: "GET_MIN_SIDE_LOGIN_SUCCESS",
          payload: { customerInfo: { ...customerInfo } }
        });
        customerActionDispatchers.getExistingCustomerDataBySsn(info);
        saleActionDispatchers.getCustomerInfoAction(info, customerInfo.phone);
        setIsAxiosCalled(true);
      })
      .catch((error) => {
        console.error("Error: Failed to get SSN and Email for MinSide: ", error);
      });
  };
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.isLoading) {
      try {
        auth.signinSilent();
        // Check for an error after attempting to sign in
        if (auth.error && (auth.error.message === "login_required" || !auth.isAuthenticated)) {
          auth.signinRedirect();
        }
      } catch (error) {
        // Existing catch block
        console.error("Error: Failed to signIn and redirect: ", error);
      }
    }
    // eslint-disable-next-line
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  useEffect(() => {
    if (auth.isAuthenticated && !isAxiosCalled && !auth.isLoading) {
      saveInfo();
    }
  }, [auth.isAuthenticated]);

  async function verifyTobbId(tobbId) {
    let response = await AxiosFunction.verifyMembershipNumber(tobbId);
    return response;
  }

  const handleConfirm = async () => {
    let verificationResult = await verifyTobbId(tobbId);
    if (verificationResult) {
      setIsValidNumber(true);
      setButtonPressed(true);
      tobbActionDispatchers.setTobbId(tobbId);
      tobbActionDispatchers.submitTobbForm(form, tobbId);
    } else {
      setIsValidNumber(false);
    }
  };

  let tobbMedlemsnummer = tobbId;
  const validInput = tobbMedlemsnummer && tobbMedlemsnummer.replace(/\s+/g, "").length === 9;
  const handleChange = (e) => {
    setTobbId(e.target.value);
  };
  let firstName = store.getState().saleState.form.firstName;
  if (firstName !== null && firstName !== "") {
    firstName = firstName.split(" ")[0];
  }
  let regTobbid = store.getState().tobbState.tobbId;
  regTobbid = regTobbid !== null && regTobbid !== undefined ? regTobbid.tobbId : "";
  if (!isAxiosCalled) {
    return (
      <FormPageContainer hideFooter className={"loaderContainer"}>
        <div className={"loader slide"}>
          <Loader text={"Vent mens vi gjør ting klart for deg..."} />
        </div>
      </FormPageContainer>
    );
  }
  return (
    <FormPageContainer hideFooter>
      <div className={`${IsFKTheme && styles.containerFK}`}>
        <header className={`fallIn- ${styles.header}`}>
          <h1>Hva er ditt TOBB kortnummer? </h1>
          <h6>Du vil få månedlig TOBB-bonus så lenge du er kunde. </h6>
        </header>
      </div>
      <div className={"slide"}>
        <SimpleInput
          className={styles.powerMeterInput}
          label={"Kortnummer"}
          value={tobbId}
          type={"tobbId"}
          name={"tobbId"}
          onChange={handleChange}
          placeholder={"9 siffer"}
          showWhiteBox
          autofocus
          showErase={tobbMedlemsnummer !== ""}
          focusDelay={pageStepAnimationDuration}
          disabled={buttonPressed && isValidNumber}
        />
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <div className={styles.centeredButtonContainer}>
            <button
              className={`btnPrimary ${styles.submitButton}`}
              disabled={!validInput || (buttonPressed && isValidNumber)}
              onClick={handleConfirm}
            >
              {tobbSelector.isLoading ? (
                <div className="d-flex align-items-center flex-column">
                  Søker
                  <CircularProgress
                    sx={{ color: "#FF5500FF" }}
                    thickness={4}
                    disableShrink
                    variant="indeterminate"
                    color="inherit"
                    size={20}
                  />
                </div>
              ) : (
                "Bekreft"
              )}
            </button>
          </div>
        </Box>
        {isValidNumber === true ? (
          <>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              {buttonPressed && (
                <>
                  <Typography
                    className={`fallIn- ${styles.header}`}
                    style={{ textAlign: "center", fontSize: 25 }}
                  >
                    Ditt kortnummer er registrert.
                  </Typography>
                </>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              {buttonPressed && (
                <>
                  <Typography
                    className={`fallIn- ${styles.header}`}
                    style={{ textAlign: "center", fontSize: 15 }}
                  >
                    Du kan nå lukke denne siden.
                  </Typography>
                </>
              )}
            </Box>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <>
              <Typography
                className={`fallIn- ${styles.header}`}
                style={{ textAlign: "center", fontSize: 20 }}
              >
                Kortnummeret er ikke gyldig. Prøv igjen.
              </Typography>
            </>
          </Box>
        )}
      </div>
    </FormPageContainer>
  );
}

export default connect(formAndUserSelector)(Tobb);
