import React, { Component } from "react";
import "./App.scss";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Layout from "./Layout/Layout";
import Preloader from "./components/Preloader/Preloader";
import UrlEncoder from "./UrlEncoder/UrlEncoder";
import TrumfCallback from "./components/Trumf/TrumfCallback";
import { pages } from "./Utils/CustomerFormHelper";
import { IsFKTheme, muiTheme } from "./Utils/Themes";
import CustomerForm from "./components/CustomerForm/CustomerForm";
import AddTrumfProductPage from "./components/Trumf/AddTrumfProduct/AddTrumfProductPage";
import BankIdCallback from "./components/CustomerForm/Identification/BankId/BankIdCallback";
import SmsAcceptPage from "./components/SmsAcceptLandingPage/SmsAcceptPage";
import MoveInLandingPage from "./components/MoveIn/MoveInLandingPage";
import MissingMeterNumber from "./components/MeterNumberPage/MissingMeterNumber";
import { ThemeProvider } from "@mui/styles";
import saleActionDispatchers from "./ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import MobileCustomerForm from "./components/CustomerForm/MobileCustomerForm";
import BedriftCustomerForm from "./components/CustomerForm/BedriftCustomerForm";
import PreviewAddon from "./components/CustomerForm/PreviewAddon/PreviewAddon";
import PreviewAddonGroup from "./components/CustomerForm/PreviewAddon/PreviewAddonGroup";
import Tobb from "./components/TOBB/Tobb";
import TobbEtterRegistrering from "./components/TOBB/TobbEtterRegistrering";
import MinSideLogIn from "./components/CustomerForm/Identification/MinSide/MinSideLogIn";
import ExistingAgreement from "./components/CustomerForm/ExistingAgreement/ExistingAgreement";
import ConfirmExistingAgreement from "./components/CustomerForm/ExistingAgreement/ConfirmExistingAgreement";
import { TrackingPixel } from "./Utils/TrackingPixel";
import { AdditionalSalesPreview } from "./components/CustomerForm/AdditionalSales/AdditionalSalesPreview";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
export const history = createBrowserHistory();

export default class App extends Component {
  componentDidMount() {
    const favicon = document.getElementById("favicon");
    favicon.href = IsFKTheme ? "faviconFK.ico" : "faviconTK.ico";
    saleActionDispatchers.setSessionId();
  }

  static displayName = App.name;

  render() {
    return (
      <ThemeProvider theme={muiTheme}>
        <Layout>
          <Router basename={baseUrl} history={history}>
            <Switch>
              <Route exact path={pages.missingMeterNumber} component={MissingMeterNumber} />
              <Route exact path={pages.smsAcceptLandingPage} component={SmsAcceptPage} />
              <Route exact path={pages.tobb} component={TobbEtterRegistrering} />
              <Route exact path={pages.trumfCallback} component={TrumfCallback} />
              <Route exact path={pages.bankIdCallback} component={BankIdCallback} />
              <Route exact path={pages.existingAgreement} component={ExistingAgreement} />
              <Route
                exact
                path={pages.confirmExistingAgreement}
                component={ConfirmExistingAgreement}
              />
              <Route exact path="/urlEncoder" component={UrlEncoder} />
              <Route exact path="/preview/Tilleggsprodukt/:id" children={<PreviewAddon />} />
              <Route exact path="/preview/additional-sales" children={<AdditionalSalesPreview />} />
              <Route
                exact
                path="/preview/TilleggsproduktGruppe/:tag"
                children={<PreviewAddonGroup />}
              />
              <Route path={pages.moveInLandingPage} component={MoveInLandingPage} />
              <Route path={pages.addTrumfProduct} component={AddTrumfProductPage} />
              <Route path={pages.mobile} component={MobileCustomerForm} />
              <Route path={pages.bedrift} component={BedriftCustomerForm} />
              <Route path={pages.minSideFKLogIn} component={MinSideLogIn} />
              <Route path={pages.minSideTKLogin} component={MinSideLogIn} />
              <Route path="/" component={CustomerForm} />
            </Switch>
            <TrackingPixel />
          </Router>
          <Preloader />
        </Layout>
      </ThemeProvider>
    );
  }
}
