import React from "react";
import FormPageContainer from "../../CustomerForm/FormPageContainer/FormPageContainer";
import styles from "./Receipt.module.scss";
import { connect } from "react-redux";
import { smsAcceptReceiptSelector } from "../../../ReduxModules/Selectors/SmsAcceptSelector";
import CustomerInfo from "../CustomerInfo/CustomerInfo";
import Loader from "../../CustomerForm/FKLoader/Loader";
import { IsFKTheme } from "../../../Utils/Themes";
import { powerLink } from "../../../Utils/ExternalLinks";
import Icon from "../../Icon/Icon";

function Receipt({ customerInfo, isLoading, error, acceptedOrders }) {
  return error ? (
    <FormPageContainer hideFooter>
      <h3>Innsending feilet</h3>
      <div>{error.message}</div>
    </FormPageContainer>
  ) : isLoading ? (
    <FormPageContainer hideFooter>
      <Loader text={"Sender inn bestilling..."} />
    </FormPageContainer>
  ) : (
    <FormPageContainer hideFooter className={IsFKTheme && styles.containerFK} wide>
      <div className={styles.innerFormPageContainer}>
        <h1>Takk for din bestilling, den er nå registrert!</h1>
        <h5>{"Du vil snart motta en e-post med\nbekreftelse på bestillingen din."}</h5>
        {customerInfo && (
          <CustomerInfo
            acceptedOrders={acceptedOrders}
            confirmed={true}
            customerInfo={customerInfo}
            showHouse={IsFKTheme}
          />
        )}
        <div className={`${styles.confirmMessage}`}>Bestilling bekreftet!</div>
        <div className={`${styles.arrowContainer}`}>
          <a href={powerLink} target="blanc">
            <Icon icon={"ArrowLeft"} className={styles.arrow} />{" "}
            {IsFKTheme ? "fjordkraft.no" : "trøndelagkraft.no"}
          </a>
        </div>
      </div>
    </FormPageContainer>
  );
}

export default connect(smsAcceptReceiptSelector)(Receipt);
