import config from "../config";
import { createTheme } from "@mui/material";

export const Theme = {
  FK: "FK",
  TK: "TK"
};

export const IsFKTheme = config.theme === Theme.FK;

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#FF5500",
      dark: "#b23b00"
    },
    secondary: {
      main: "#72A138",
      dark: "#4f7027",
      light: "#8eb35f"
    }
  },
  fjordkraftPalette: {
    orange: "#FF5500",
    orange2: "#E94E00",
    green: "#72A138",
    black: "#000000",
    gray1: "#3D3D3F",
    gray2: "#767676",
    gray3: "#999999",
    gray4: "#A7A9AC",
    gray5: "#D1D3D4",
    gray6: "#DDDEDF",
    gray7: "#E0E0E0",
    gray8: "#E8E9E9",
    white: "#EEEEEE",
    darkGreen: "#6A9435",
    darkBlue: "#007EA4",
    functionGreen: "#8B9D59",
    blue: "#00A2B9",
    darkOrange: "#D34700",
    darkOrange2: "#9E3500",
    lightOrange: "#FF814A",
    dark: "#282828",
    yellow: "#ffe300",
    lightYellow: "#ffea42",
    differentYellow: "#ffe61a",
    TKTeal: "#7DECE8",
    TKDarkTeal: "#26E0D8",
    additionalColor0: "#6A9435",
    additionalColor1: "#007EA4",
    additionalColor2: "#00A2B9",
    additionalColor3: "#D34700",
    additionalColor4: "#282828"
  }
});
