import React, { useEffect } from "react";
import "./FKStyles.scss";
import "./TKStyles.scss";
import { IsFKTheme } from "../Utils/Themes";

function Layout(props) {
  useEffect(() => {
    document.body.style.backgroundColor = IsFKTheme ? "#FF5500" : "#7DECE8";
    document.body.classList.add(IsFKTheme ? "fkStyles" : "tkStyles");
  }, []);
  return <>{props.children}</>;
}

export default Layout;
