import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./ConfirmExistingAgreement.module.scss";
import { pages } from "../../../Utils/CustomerFormHelper";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import FKLoader from "../FKLoader/FKLoader";
import ProductInfoModal from "../ProductInfoModal/ProductInfoModal";
import { Box, Grid, Paper } from "@mui/material";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import classes from "./ExistingAgreementProductCard.module.scss";
import { ReactComponent as GreenCheckMark } from "../../../assets/Images/FK/GreenCheckMark.svg";
import Typography from "@mui/material/Typography";
import { IsFKTheme } from "../../../Utils/Themes";
import * as AxiosFunction from "../../../Utils/AxiosFunctions";
import { getProductHubProductText } from "../../../Utils/AxiosFunctions";
import { getPriceAreaTags } from "../../../Utils/HelperFunctions";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import config from "../../../config";

function ConfirmExistingAgreement() {
  const lastProduct = useSelector((state) => state.customerState.lastProduct);
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState("");
  const [isLoadingDescription, setIsLoadingDescription] = useState(false);
  const history = useHistory();
  const [productHubProductFetched, setProductHubProductFetched] = useState(false);
  const forms = useSelector((state) => state.saleState.form);
  const [isLoading, setIsLoading] = useState(true);
  const termsBaseUrl =
    config.env === "prod"
      ? "https://avtale.fjordkraft.no/api/term/"
      : "https://termsandconditions-fjordkraftservices-test.azurewebsites.net/api/term/";
  const productPriceInfo = useSelector((state) => state.productState?.priceInfo);
  const productHubInfo = forms.producthubInfo;
  const powerTermsGuid = productHubInfo.guid ?? "error";
  let no4 =
    productHubInfo.tags !== null &&
    productHubInfo.tags !== undefined &&
    productHubInfo.tags.some((item) => item.includes("NO4"));
  let tags = no4;
  const [currentProductId, setCurrentProductId] = useState(lastProduct?.id);

  if (productHubInfo?.id && productHubInfo?.id !== currentProductId) {
    setCurrentProductId(productHubInfo?.id);
  }

  const headerTheme = createTheme({ typography: { h1: { fontSize: "2.5rem" } } });

  useEffect(() => {
    onbeforeunload = (e) =>
      `Endringene dine vil bli slettet. Er du sikker på at du vil forlate siden?`;
    return () => {
      onbeforeunload = null;
    };
  });

  const fetchPriceInfo = async (productId) => {
    try {
      productActionDispatchers.getPriceInfo(productId);
      const callToActionResult = await getProductHubProductText(productId, 6, 7);
      setDescription(callToActionResult);
    } catch (error) {
      console.error("Error fetching price info:", error);
    }
  };

  useEffect(() => {
    if (!currentProductId) {
      history.push(pages.existingAgreement);
    }
  }, [currentProductId, history]);

  useEffect(() => {
    const fetchData = async (productId) => {
      setIsLoading(true);
      setIsLoadingDescription(true);
      try {
        // Fetch product information with bonuses
        const productResult = await AxiosFunction.GetProductHubProductWithBonuses(productId);
        // Fetch terms and conditions guid
        const guid = await AxiosFunction.GetTermsAndConditionsGuidById(
          productResult?.termsAndConditionsId
        );

        // Process data after both requests are complete
        let newProducthubInfo = {
          ...productResult,
          priceAreaTags: getPriceAreaTags(productResult),
          guid
        };
        saleActionDispatchers.setProducthubInfo(newProducthubInfo);
        setProductHubProductFetched(true);

        // Fetch price info after setting product info
        await fetchPriceInfo(productId);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingDescription(false);
      }
    };

    if (currentProductId) {
      fetchData(currentProductId).then(() => setIsLoading(false));
    }
  }, [currentProductId]);

  const handleDifferentProductClicked = () => {
    history.push(pages.existingAgreement);
  };

  const handlePrices = (monthly, deno) => {
    let price =
      productPriceInfo !== undefined &&
      productPriceInfo !== null &&
      productPriceInfo?.value?.find((price) => {
        if (monthly) {
          return price.name.includes("Månedlig");
        } else {
          return price.name.includes("Påslag strøm");
        }
      });
    if (price) {
      if (deno) {
        return (
          (tags ? price?.vatExclusivePrice : price.vatInclusivePrice) + " " + price.denomination
        );
      } else {
        return tags ? price?.vatExclusivePrice : price.vatInclusivePrice;
      }
    } else {
      return 0;
    }
  };

  return (
    <FormPageContainer className={`${styles.container} ${IsFKTheme && styles.containerFK}`}>
      {IsFKTheme && <div className={styles.houseImgContainer} />}

      {isLoading || !productHubProductFetched || !productHubInfo?.id ? (
        <FKLoader />
      ) : (
        <Box
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          <ThemeProvider theme={headerTheme}>
            <Typography sx={{ margin: "auto" }} align="center" variant="h1">
              Din strømavtale
            </Typography>
          </ThemeProvider>

          <Grid item xs={12} sx={{ mb: 3 }} className={styles.existingProductCardWrapper}>
            <Paper
              elevation={8}
              className={"whiteBox"}
              sx={{
                ml: -5,
                mr: -5,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "left",
                    mb: 1,
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "normal",
                    marginBottom: 0
                  }}
                >
                  <GreenCheckMark style={{ marginTop: "4px" }} />
                  <Typography variant="h5" sx={{ fontWeight: "bold", marginLeft: "10px" }}>
                    <span>{productHubInfo.name}</span>
                  </Typography>
                </Grid>
                <Grid item direction="column" xs={10}>
                  {!isLoadingDescription && (
                    <div className={classes.smallProductCardProduct}>
                      <h5 className={classes.smallProductCardTitle}>
                        {/* TODO: ingenting her?? */}
                      </h5>
                      <p
                        className={classes.smallProductCardGrayText}
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </div>
                  )}
                </Grid>
                <Grid container spacing={3} rowSpacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ position: "left" }}>
                      Månedspris:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ textAlign: "right", fontWeight: "bold" }} variant="body1">
                      {handlePrices(true, true)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ position: "left" }}>
                      Påslag:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ textAlign: "right", fontWeight: "bold" }} variant="body1">
                      {handlePrices(false, true)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ position: "left" }}>
                      <a
                        href={termsBaseUrl + powerTermsGuid}
                        target="_blank"
                        referrerPolicy="no-referrer"
                      >
                        Avtalevilkår
                      </a>
                    </Typography>
                  </Grid>

                  {/*<Grid item xs={6}>
                    <Typography variant="body1" sx={{ position: 'left' }}>
                      Bindingstid:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant="body1">
                      Ingen
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ position: 'left' }}>
                      Bruddgebyr:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant="body1">
                      0 Kr.
                    </Typography>
                  </Grid>*/}
                </Grid>
              </Grid>
            </Paper>
            <ProductInfoModal
              show={showModal}
              closeModal={() => setShowModal(false)}
              productId={currentProductId}
            />
            <Grid container direction="column">
              <button
                type="button"
                style={{ marginTop: "20px" }}
                className={"btnPrimary " + styles.btn}
                onClick={() => pageStepActionDispatchers.goToNextStep(window.location.pathname)}
              >
                Gå videre
              </button>
              <button
                type="button"
                className={`btnBorder ${styles.changeButton}`}
                onClick={handleDifferentProductClicked}
              >
                Endre avtalen
              </button>
            </Grid>
          </Grid>
        </Box>
      )}
    </FormPageContainer>
  );
}

export default ConfirmExistingAgreement;
